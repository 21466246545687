@mixin flex($important: false)
  @if $important
    display: flex !important
    align-items: center !important
  @else
    display: flex
    align-items: center

@mixin bold
  font-weight: bold

@mixin pointer($important: false)
  @if $important
    cursor: pointer !important
  @else
    cursor: pointer

@mixin ellipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@mixin closeIcon
  content: ""
  width: 8px
  height: 8px
  background-color: rgb(var(--secondaryColor))
  mask-image: var(--icon-button-cancel)
  opacity: 0.8

@mixin arrowIcon
  min-width: 8px
  width: 8px
  height: 4px
  mask-image: var(--icon-dropdown-arrow)
  background-color: rgb(var(--secondaryColor))
  opacity: 0.8

@mixin fadeIn($duration: .3s)
  animation: fadeIn $duration
  @keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

//live scoreboards
@mixin liveScoreboard
  border: 1px solid #000
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4)
  text-shadow: -1px 0 rgba(0, 0, 0, 0.4)
  position: relative
  width: 560px

@mixin liveScoreboardHeader
  background: var(--live_gameBoardHeaderBg)
  box-shadow: inset 0 1px var(--live_gameBoardHeaderShadow)
  border-bottom: 1px solid rgba(0, 0, 0, 0.6)

@mixin liveScoreboardTitle
  @include flex
  padding-left: 8px
  height: 24px

  > div
    font-size: 11px
    margin-left: 8px
    line-height: 12px
    @include bold
    color: var(--prematch_tournamentHeaderColor)

    > span
      color: rgb(var(--secondaryColor))

@mixin liveScoreboardSubHeader
  background: var(--live_gameBoardBg2)
  border-top: 1px solid var(--live_gameBoardBorder2)
  border-bottom: 1px solid #000
  padding: 0 8px
  height: 16px
  color: rgb(var(--primaryColor))
  font-size: 10px
  text-transform: uppercase
  @include flex

// default wallet mixins //

@mixin button()
  @include flex
  justify-content: center
  color: rgb(var(--secondaryColor))
  border: 0
  border-radius: var(--base_borderRadius)
  font-size: 12px
  transition: opacity 0.3s

  &:hover:not(:active)
    cursor: pointer

  &:disabled
    opacity: 0.5
    pointer-events: none

@mixin greyButton($width, $height)
  width: $width
  height: $height
  background: var(--wallet_greyBtnBg)
  color: var(--wallet_greyBtnColor)
  &:hover:not(:active)
    background: var(--wallet_greyBtnHoverBg)
  &:active
    background: var(--wallet_greyBtnActiveBg)

  svg
    path
      fill: var(--wallet_greyBtnColor)

@mixin blue-btn()
  width: 138px
  height: 32px
  background: var(--wallet_baseBtnBg)

  &:hover:not(:active)
    background: var(--wallet_baseBtnHoverBg)
  &:active
    background: var(--wallet_baseBtnActiveBg)

@mixin grey-btn()
  @include greyButton(138px, 32px)

@mixin field-item()
  @include flex
  margin-top: 12px

  label
    margin-right: 8px
    min-width: 110px

    + div:first-of-type
      @include flex
      justify-content: space-between
      margin-left: auto
      width: 400px
      background: var(--wallet_fieldBg)
      color: rgb(var(--secondaryColor))
      padding: 0 2px 0 10px
      border-radius: var(--wallet_borderRadius)
      height: 28px
      position: relative

      button
        @include greyButton(24px, 24px)

@mixin percentBtn()
  @include flex
  justify-content: center
  height: 28px
  border-radius: var(--wallet_borderRadius)
  color: rgb(var(--secondaryColor))
  font-size: 13px
  flex: 1
  border: 1px solid var(--wallet_percentBtnBorder)
  background: var(--wallet_percentBtnBg)
  min-width: 50px
  margin-left: 6px
  transition: opacity 0.3s

  &.selected
    color: var(--wallet_percentBtnActiveColor)
    background: var(--wallet_percentBtnActiveBg)
    border-color: var(--wallet_percentBtnActiveBorder)
    pointer-events: none

  &:hover:not(:active)
    cursor: pointer
    background: var(--wallet_percentBtnHoverBg)
    border-color: var(--wallet_percentBtnHoverBorder)

  &:active
    background: var(--wallet_percentBtnActiveBg)
    border-color: var(--wallet_percentBtnActiveBorder)

  &:disabled
    opacity: .5
    pointer-events: none

@mixin amountInput()
  width: 100%
  background: var(--wallet_fieldBg)
  color: rgb(var(--secondaryColor))
  padding: 0 28px 0 10px
  font-size: 12px
  margin-left: auto
  border: 1px solid var(--wallet_fieldBg)
  @include bold
  border-radius: var(--wallet_borderRadius)
  height: 28px
  -moz-appearance: textfield

  &::placeholder
    color: rgba(var(--wallet_txtColor), .8)
    font-size: 12px
    font-weight: normal

  &.hasError
    border: 1px solid #e42d5b

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  &:focus
    outline: none
