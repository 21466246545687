@import "mixins"
.casinoSearchResult
  background: var(--casino_searchResultBg)
  border-radius: 4px
  right: 0
  min-width: 230px
  top: 32px
  max-height: 330px
  max-width: 140%
  height: auto
  position: absolute
  min-height: 72px
  overflow: auto
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8)
  display: flex
  flex-direction: column

  &::-webkit-scrollbar
    width: 10px

  &::-webkit-scrollbar-thumb
    background: rgba(var(--primaryColor), 0.3)
    border: 3px solid var(--casino_searchResultBg)
    border-radius: 10px

  & :global
    .no-game
      margin: 20px 10px
    .loader
      position: inherit
      margin: unset

      > span
        font-size: 20px

    .casino-game-item
      margin: 0 8px
      padding: 8px 0 40px
      max-width: 100%
      width: unset
      height: unset
      display: flex
      justify-content: flex-start
      align-items: flex-start
      border-radius: 0
      border: 0

      > span:first-of-type,
      > div > i
        display: none

      &:not(:last-of-type)
        border-bottom: 1px dotted var(--casino_searchResultItemSeparator)

      &:hover > div + section span
        color: rgb(var(--secondaryColor)) !important
      .casino-drops-wins
        top: unset
        width: 38px
        :global(img)
          width: 100%
          height: auto
      > div
        position: static
        margin-right: 8px
        min-width: 70px
        width: 70px !important
        height: 82px !important

        &:before,
        &:after
          content: none

        > div
          opacity: 1
          display: flex
          flex-direction: inherit
          bottom: 8px
          right: 0
          height: unset
          left: unset
          top: unset
          background: transparent
          flex-flow: row-reverse
          width: 100%
          transform: unset

          button
            height: 24px
            margin-bottom: 0
            min-width: auto
            flex: 1
            font-size: 12px

            &:nth-child(2)
              margin: 0 6px 0 0

      > section
        position: relative
        bottom: unset
        left: unset
        background: none
        height: 82px
        width: calc(100% - 80px)
        padding-left: 0
        padding-right: 22px
        line-height: 16px
        color: rgba(var(--primaryColor))
        text-transform: capitalize
        text-align: left
        font-size: 11px

        span
          text-transform: capitalize
          font-size: 12px
          margin-bottom: 4px
        > i
          right: 0
          background-color: rgba(var(--primaryColor), 0.75)

  &.sr-search-livecasino :global(.casino-game-item)
    align-items: center
    padding-bottom: 8px

    > section
      margin: 0
