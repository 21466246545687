@import "mixins"
.inputStyle
  border: 0
  background: transparent
  border-bottom: 1px solid var(--field_borderColor)
  padding: 4px 0
  outline: 0 !important
  font-size: 12px
  color: var(--common_field_color)
  width: 100%

  &::placeholder
    color: var(--common_field_color)
    opacity: 0.5

.inputStyle:-webkit-autofill,
.inputStyle:-webkit-autofill:hover,
.inputStyle:-webkit-autofill:focus,
.inputStyle:-webkit-autofill:active
  box-shadow: inset 0 0 0 30px var(--field_borderColor)
  color: var(--common_field_color)
  -webkit-text-fill-color: var(--common_field_color)
  border-bottom: 1px solid var(--field_borderColor)

.label
  text-transform: uppercase
  font-size: var(--base_smallFontSize)
  line-height: calc(var(--base_smallFontSize) + 1px)
  @include flex
  color: var(--common_field_labelColor)

.error
  color: rgb(var(--particularColor2))
  font-size: var(--base_smallFontSize)
  line-height: calc(var(--base_smallFontSize) + 1px)
  position: relative
  top: 5px
  display: block
  overflow: hidden
  height: auto
  max-height: 0
  opacity: 0
  animation: maxHeight .4s
  animation-fill-mode: forwards
  @keyframes maxHeight
    50%
      opacity: 1
    100%
      max-height: 100px
      opacity: 1

.fieldError
  position: relative

  input
    border-color: rgb(var(--particularColor2)) !important
