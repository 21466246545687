@import "mixins"
.block
  position: relative

  &:global(.email)
    min-height: 168px
    border-bottom: 1px solid var(--verification_border)
    margin-bottom: 8px

    &:has(.successfullyVerified)
      border-bottom: 0

  &:global(.phone)
    min-height: 126px

  > span
    @include bold
    text-transform: uppercase
    margin-bottom: 8px
    @include flex
    height: 30px
    background: rgba(231, 234, 240, 0.6)
    padding: 0 8px

  > div
    line-height: 16px
    margin-bottom: 8px
    padding: 0 8px

.verifyForm
  padding: 0 8px

  > span
    width: 100%
    @include bold
    margin-bottom: 6px
    display: block

.verifyFormFields
  @include flex

  input
    width: 30px
    height: 30px
    background: var(--common_verification-inputBg)
    border: 0
    border-radius: 2px
    margin-right: 4px
    color: var(--common_verification-inputColor)
    font-size: 12px
    padding: 0 4px
    text-align: center

    &:disabled
      opacity: .6

    &:focus
      background-color: var(--common_verification-inputFocusBg)
      box-shadow: inset 0 0 0 1px var(--common_verification-inputBorder)

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active
      box-shadow: inset 0 0 0 30px var(--common_verification-inputBg)
      -webkit-text-fill-color: var(--common_verification-inputColor)
      color: var(--common_verification-inputColor)

.resendTxt,
.wrongTxt
  padding-top: 6px

.resendTxt
  position: absolute
  bottom: 6px

.wrongTxt
  color: rgb(var(--particularColor2))

.successfullyVerified
  @include flex
  flex-direction: column
  justify-content: center
  color: #42b147
  max-width: 230px
  text-align: center
  line-height: 18px
  font-size: 13px
  top: 50%
  transform: translate(-50%, -50%)
  left: 50%
  position: absolute

  .iconVerified
    display: inline-flex
    margin-bottom: 6px
    min-width: 12px
    > span
      display: inline-flex

.requestCodeBtn
  width: auto
  max-width: unset
  min-width: auto
  height: 30px
  flex: 1
  background: var(--common_verification-requestCodeBtnBg)
  color: var(--common_verification-requestCodeBtnColor)

  &:hover
    background: var(--common_verification-requestCodeBtnHoverBg)
  &:active
    background: var(--common_verification-requestCodeBtnActiveBg)

.verifyBtn
  background: #5cba60
  width: auto
  max-width: unset
  min-width: auto
  flex: 1
  height: 30px

  &:hover:not(:active)
    background: #4bae4f

  &:active
    background: #3fa143
    box-shadow: none
