@import "mixins"
.fxRate
  @include flex
  font-size: 11px
  line-height: 12px
  height: 20px
  background: var(--header_fxRateBg)
  border-radius: 2px
  padding: 0 2px 0 8px
  white-space: nowrap

  strong
    color: rgb(var(--secondaryColor))
    margin-left: 4px
    min-width: 42px
    margin-right: 2px

  > span
    background: var(--header_fxRateCurrencyBg)
    @include flex
    align-items: flex-end
    height: 16px
    min-width: 50px
    margin-left: 6px
    padding: 0 6px 2px
    line-height: 12px
    border-radius: 2px
