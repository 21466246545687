@import "mixins"
.container
  composes: register from "../signup/details/layouts/default/default.module.sass"

.header
  @include flex
  min-height: 48px
  padding: 6px 0 4px
  border-bottom: var(--login_headerBorder)
  border-bottom-style: dotted
  font-size: 20px
  line-height: 22px
  color: rgb(var(--particularColor2))
  @include bold
  margin-bottom: 12px

.verification
  display: flex
  flex-direction: column
  flex: 1
  color: rgba(var(--secondaryColor))
  margin: 0 -8px
  > div
    flex: 1
    display: flex
    flex-direction: column

.pleaseVerifyTxt
  color: rgb(var(--particularColor))
  @include bold
  display: block
  margin: 10px 0 18px
  line-height: 18px
  font-size: 13px
  padding: 0 8px

.finishButton
  width: 100%
  max-width: 100%
  border-radius: 2px
  height: 30px
  font-size: 12px
  margin-top: auto
