@import "mixins"
.login
  background: var(--header_loginPanelBg)
  padding: var(--header_loginPanelPadding)
  border-radius: 2px
  box-shadow: var(--header_loginPanelShadow)
  position: relative
  @include flex

.userIcon
  background: var(--header_loginUserIconBg)
  width: var(--header_userIconSize)
  height: var(--header_userIconSize)
  box-shadow: inset 0 0 0 1px var(--header_loginUserIconShadow)
  border-radius: 100%
  left: calc(var(--header_userIconSize) / 2 * -1)
  position: absolute
  @include flex
  justify-content: center
  > span
    @include flex
  svg
    path
      fill: var(--header_loginUserIconFill)
    > *:not(path)
      opacity: 0.7
      filter: grayscale(100%)

.separator
  height: 15px
  border-right: var(--header_loginPanelSeparator)

.registerBtn
  height: var(--header_btnHeight)
  margin-left: 10px
  background: rgb(var(--particularColor))
  font-size: var(--base_fontSize)
  min-width: 110px
  max-width: 200px

  &:hover
    background: var(--button_secondaryHoverBg)

  &:active
    background: var(--button_secondaryActiveBg)

.loginBtn
  height: var(--header_btnHeight)
  min-width: var(--header_loginBtnWidth)
  margin-left: 10px
  font-size: var(--base_fontSize)
  background: var(--header_loginBtnBg)
  color: var(--header_loginBtnColor)
  border: 1px solid var(--header_loginBtnBorder)
  &:hover
    background: var(--header_loginBtnHoverBg)
  &:active
    background: var(--header_loginBtnActiveBg)
