@import "mixins"
.oddsDropdown
  > div:first-of-type
    min-width: 98px
    border: 0 !important
    color: rgb(var(--primaryColor)) !important
    font-size: 11px !important
    padding: 4px 8px !important
    min-height: 27px

    > span
      padding-right: 8px
      @include flex

      > i
        margin-right: 8px

    &:after
      background-color: rgb(var(--secondaryColor)) !important
      margin-left: auto
      opacity: 0.7
      margin-top: -1px

    &:hover:after
      opacity: 1

  &:hover > div:first-of-type
    color: rgba(var(--secondaryColor), 0.9)

    &:after
      border-color: rgba(var(--secondaryColor), 0.8) transparent transparent transparent

  > section
    background: var(--customDdl_bg)
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5), -1px 1px 4px rgba(0, 0, 0, 0.5)
    max-height: unset

    > div
      max-height: unset

    > div:last-of-type > div
      background: var(--customDdl_bg)

    ul > li
      @include flex
      padding: 0 8px
      height: 26px
      border-top: 1px solid var(--customDdl_borderTop)
      border-bottom: 1px solid var(--customDdl_borderBottom)
      color: rgb(var(--secondaryColor)) !important

      &:last-of-type
        border-bottom: 0

      > i
        margin-right: 8px

    & :global
      li:hover:not(.selected-li)
        background: rgba(var(--customDdl_activeBg), 0.5)
        border-top-color: rgba(var(--customDdl_activeBg), 0.8)

      li.selected-li
        background: rgb(var(--customDdl_activeBg))
        border-top-color: rgb(var(--customDdl_activeBg))
        cursor: default